export default [
  {
    id: 1,
    url: "our-history",
    label: "our history",
  },
  {
    id: 2,
    url: "the-london-gallery",
    label: "the london gallery",
  },
  {
    id: 3,
    url: "the-milan-galleries",
    label: "the milan galleries",
  },
  {
    id: 4,
    url: "services",
    label: "services",
  },
  {
    id: 5,
    url: "art-fairs",
    label: "art fairs",
  },
  {
    id: 6,
    url: "press",
    label: "press",
  },
  {
    id: 7,
    url: "news",
    label: "news",
  },
]
